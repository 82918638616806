$(()=> {
    const $tabItem = $(".js-tab-item")
    const $modelsItem = $(".js-model-item");

    $tabItem.on("click", (e)=> {
        const target = $(e.target)
        const model = target.attr("data-model").toLowerCase()
        $tabItem.removeClass("active")
        target.addClass("active")

        $modelsItem.each((i, car)=> {
            const name = car.dataset.model.toLowerCase()
            if(model === 'all') return car.style.display="flex"
            if(name === model) return car.style.display="flex"
            car.style.display="none"
        })
    })
})