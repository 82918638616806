$(()=> {

    $(".js-form-select").select2({
        language: { noResults: ()=>  "Ничего не найдено"}
    })

    let models = [];
    $.ajaxSetup({ async: false });
    $.getJSON('/_file/stock/new.json', function(element) {
        $.each(element, function(key, value) {
            let model_push = [];
            $.each(value, function(key, value) {
                if (key == 'color') model_push['color'] = value;
                if (key == 'complectation') model_push['complectation'] = value;
                if (key == 'model') model_push['model'] = value;
                if (key == 'engine') model_push['engine'] = value;
            });
            models.push(model_push);
        });
    });
    $.ajaxSetup({ async: true });

    $(".js-form-select").on('change', function(){

        //получаем значения селекторов
        var model = $('.js-form-select[name=model]').val();
        var complectation = $('.js-form-select[name=complectation]').val();
        var engine = $('.js-form-select[name=engine]').val();
        var color = $('.js-form-select[name=color]').val();

        var search_keys = {};
        if (model !== 'Модель') search_keys['model'] = model;
        if (complectation !== 'Комплектация') search_keys['complectation'] = complectation;
        if (engine !== 'Двигатель') search_keys['engine'] = engine;
        if (color !== 'Цвет') search_keys['color'] = color;

        var filter = {
            'model': ['Модель',],
            'complectation': ['Комплектация',],
            'engine': ['Двигатель',],
            'color': ['Цвет',],
        }

        //фильтруем базу машин - подготавливаем select filter
        $.each(models, function( model_key, model ) {
            var check_add_to_select_filter = true;

            $.each(search_keys, function( search_key, search_this ) {
                if (model != 'Модель' && $.inArray( model['model'], filter['model'] ) === -1 && $.type(model['model']) === "string") filter['model'].push(model['model']);
                if ((model[search_key] != search_this  && search_key != 'complectation') && (model[search_key] != search_this  && search_key != 'engine')  && (model[search_key] != search_this  && search_key != 'color')) check_add_to_select_filter = false; //&& search_key != 'complectation'
                //if (model[search_key] != search_this ) check_add_to_select_filter = false;
            });
            if (check_add_to_select_filter) {
                if ($.inArray( model['complectation'], filter['complectation'] ) === -1) {
                    filter_make = true;
                    if ($.type(model['complectation']) === "string") filter['complectation'].push(model['complectation']);
                }
                if ($.inArray( model['engine'], filter['engine'] ) === -1) {
                    filter_make = true;
                    if ($.type(model['engine']) === "string") filter['engine'].push(model['engine']);
                }
                if ($.inArray( model['color'], filter['color'] ) === -1) {
                    filter_make = true;
                    if ($.type(model['color']) === "string") filter['color'].push(model['color']);
                }
            }
        });
        $('.js-form-select option:not(:selected)').remove();

        $.each(filter, function( filter_select, filter_values ) {
            $.each(filter_values, function( key, value ) {
                $('.js-form-select[name='+filter_select+']').append($('<option>', {
                    value: value,
                    text : value,
                }));
            });
        });

        $('.stock__list-item').hide();
        var filter_data = '';
        if (model !== 'Модель') filter_data += '[data-category-model="' + model + '"]';
        if (complectation !== 'Комплектация') filter_data += '[data-category-complectation="' + complectation + '"]';
        if (engine !== 'Двигатель') filter_data += '[data-category-engine="' + engine + '"]';
        if (color !== 'Цвет') filter_data += '[data-category-color="' + color + '"]';

        if ($('.stock__list-item'+filter_data).length == 0) {
            $('.model__not-available').html('Автомобиля нет в наличии');
        }else $('.model__not-available').html('');
        $('.stock__list-item'+filter_data).show();
    });
    $(".js-form-select").first().trigger('change');

})
