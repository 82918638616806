$(()=> {
    ymaps.ready(init); 
	let newMap;

	function init() {
		newMap = new ymaps.Map("ymaps", {
            center: [55.467519478197076,37.56004354662704],
            zoom: 12,
            controls: []
		});

        const placemark = new ymaps.Placemark([55.467519478197076,37.56004354662704], {
            hintContent: "Kia",
            hideIcon: false
        });
        
        newMap.geoObjects.add(placemark);
        const width = $(window).width()
        
        if(width < 770) newMap.behaviors.disable('drag');
	};
})
