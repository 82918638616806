import "%modules%/header/header";
// import "%modules%/footer/footer";
import "./global";
import "%modules%/intro/intro";
import "%modules%/form/form";
import "%modules%/timer/timer";
import "%modules%/tab/tab";
import "%modules%/map/map";
import "%modules%/modal/modal"
import "%modules%/stock/stock";
