import { inputsChange } from './components/inputsChange';
import { handlingApplication, handlingApplication2 } from "./components/handlers";
import { magnificPopupTrigger } from './components/magnificPopupTrigger';

$(()=> {
    const $form = $('.js-form');
    const $uploadBtn = $(".js-upload-btn");

    const regex = '\\+7 \\([0-6,9]{1}[0-9]{2}\\) [0-9]{3}-[0-9]{2}-[0-9]{2}'

    $("[type='tel']").inputmask({regex});

    let formValue = null

    $form.on('submit', function(e) {
        e.preventDefault()
        const target = e.target
        const url = target.action
        const formInput = $(this).find('.js-form-input')
        const formBtn = $(this).find('.js-form-submitjs-form-submit')

        const policy = $(this).find('.js-policy')
        inputsChange(formInput)

        const formDataEntries = new FormData(target)

        if(target.dataset.id === 'form-application') formValue = handlingApplication(formDataEntries, formInput)
        if(target.dataset.id === 'form-application2') formValue = handlingApplication2(formDataEntries, formInput)

        if(policy.val() && !policy.is(":checked")) {
            policy.parent().addClass("error")
            policy.on("change", (e)=> $(e.target).parent().removeClass("error"))
            return
        }

        if(formValue === "error") return

        formBtn.attr('disabled', true)
        formInput.addClass('disabled')

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(formValue)
        })
        .then(()=> {
            target.reset()
            formInput.blur()
            formInput.removeClass('disabled')
            formBtn.removeAttr('disabled')
            magnificPopupTrigger()
        }).catch(()=> {
            formInput.removeClass('disabled')
            formBtn.removeAttr('disabled')
        })
    })
})
